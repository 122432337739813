@font-face {
  font-family: 'default-icons';
  src:  url('fonts/default-icons.eot?g6ufzk');
  src:  url('fonts/default-icons.eot?g6ufzk#iefix') format('embedded-opentype'),
    url('fonts/default-icons.ttf?g6ufzk') format('truetype'),
    url('fonts/default-icons.woff?g6ufzk') format('woff'),
    url('fonts/default-icons.svg?g6ufzk#default-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'default-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add-square:before {
  content: "\e900";
}
.icon-arrow-down-left:before {
  content: "\e901";
}
.icon-arrow-up-right:before {
  content: "\e902";
}
.icon-audio-settings:before {
  content: "\e903";
}
.icon-bank:before {
  content: "\e904";
}
.icon-calculator:before {
  content: "\e905";
}
.icon-calendar:before {
  content: "\e906";
}
.icon-cancel_icon:before {
  content: "\e907";
}
.icon-card:before {
  content: "\e908";
}
.icon-chart-breakout-circle:before {
  content: "\e909";
}
.icon-chevron-down:before {
  content: "\e90a";
}
.icon-chevron-left:before {
  content: "\e90b";
}
.icon-chevron-right:before {
  content: "\e90c";
}
.icon-chevron-up:before {
  content: "\e90d";
}
.icon-coin-number:before {
  content: "\e90e";
}
.icon-coins-rotate:before {
  content: "\e90f";
}
.icon-copy:before {
  content: "\e910";
}
.icon-document:before {
  content: "\e911";
}
.icon-edit-contained:before {
  content: "\e912";
}
.icon-eraser:before {
  content: "\e913";
}
.icon-eye-closed:before {
  content: "\e914";
}
.icon-eye-open:before {
  content: "\e915";
}
.icon-file-check:before {
  content: "\e916";
}
.icon-file-multiple:before {
  content: "\e917";
}
.icon-filter:before {
  content: "\e918";
}
.icon-folder-open:before {
  content: "\e919";
}
.icon-headphones:before {
  content: "\e91a";
}
.icon-intersect:before {
  content: "\e91b";
}
.icon-line-chart-up:before {
  content: "\e91c";
}
.icon-list:before {
  content: "\e91d";
}
.icon-logout:before {
  content: "\e91e";
}
.icon-paperclip:before {
  content: "\e91f";
}
.icon-receipt-lines:before {
  content: "\e920";
}
.icon-save:before {
  content: "\e921";
}
.icon-settings:before {
  content: "\e922";
}
.icon-sort-vertical:before {
  content: "\e923";
}
.icon-transfer-vertical:before {
  content: "\e924";
}
.icon-trash:before {
  content: "\e925";
}
.icon-user-profile-square:before {
  content: "\e926";
}
.icon-users-profiles:before {
  content: "\e927";
}
.icon-wallet:before {
  content: "\e928";
}
.icon-question:before {
  content: "\e929";
}
.icon-info:before {
  content: "\e92a";
}
.icon-check:before {
  content: "\e92b";
}
.icon-money-change:before {
  content: "\e92c";
}
.icon-dollar-square:before {
  content: "\e92d";
}
.icon-archive:before {
  content: "\e92e";
}
.icon-folder-cloud:before {
  content: "\e92f";
}
.icon-data:before {
  content: "\e930";
}
.icon-presentation:before {
  content: "\e931";
}
.icon-terminal:before {
  content: "\e932";
}
.icon-dot-bar:before {
  content: "\e933";
}
.icon-bitcoin-card:before {
  content: "\e934";
}
.icon-bitcoin-convert:before {
  content: "\e935";
}
.icon-bitcoin-refresh:before {
  content: "\e936";
}
.icon-book:before {
  content: "\e937";
}
.icon-book-1:before {
  content: "\e938";
}
.icon-briefcase:before {
  content: "\e939";
}
.icon-buy-crypto:before {
  content: "\e93a";
}
.icon-call:before {
  content: "\e93b";
}
.icon-call-calling:before {
  content: "\e93c";
}
.icon-card-coin:before {
  content: "\e93d";
}
.icon-chart-square:before {
  content: "\e93e";
}
.icon-convert-3d-cube:before {
  content: "\e93f";
}
.icon-gallery:before {
  content: "\e940";
}
.icon-gallery-add:before {
  content: "\e941";
}
.icon-gallery-edit:before {
  content: "\e942";
}
.icon-gallery-remove:before {
  content: "\e943";
}
.icon-gallery-slash:before {
  content: "\e944";
}
.icon-group:before {
  content: "\e945";
}
.icon-link:before {
  content: "\e947";
}
.icon-location:before {
  content: "\e948";
}
.icon-lock:before {
  content: "\e949";
}
.icon-maximize-circle:before {
  content: "\e94a";
}
.icon-messages:before {
  content: "\e94b";
}
.icon-money-recive:before {
  content: "\e94c";
}
.icon-money-send:before {
  content: "\e94d";
}
.icon-notification:before {
  content: "\e94e";
}
.icon-notification-bing:before {
  content: "\e94f";
}
.icon-percentage-square:before {
  content: "\e950";
}
.icon-security-card:before {
  content: "\e952";
}
.icon-sms:before {
  content: "\e953";
}
.icon-trade:before {
  content: "\e955";
}
.icon-volume-slash:before {
  content: "\e956";
}
.icon-id-card:before {
  content: "\e957";
}
.icon-verification:before {
  content: "\e958";
}
.icon-login:before {
  content: "\e959";
}
.icon-reports:before {
  content: "\e95a";
}
.icon-reversed-dot-bar:before {
  content: "\e95b";
}
.icon-usdt:before {
  content: "\e946";
}
.icon-wallet-remove:before {
  content: "\e954";
}
.icon-wallet-change:before {
  content: "\e951";
}
